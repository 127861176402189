import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import search_icon from '../../assets/search-icon.png';
import message_icon from '../../assets/message-icon.png';
import menu_icon from '../../assets/menu-icon.png';
import dashboard_icon from '../../assets/dashboard-icon.png';
import dashboard_icon_active from '../../assets/dashboard-icon-active.png';
import calender_icon from '../../assets/calender-icon.png';
import calender_icon_active from '../../assets/calender-icon-active.png';
import reports_icon from '../../assets/reports-icon.png';
import reports_icon_active from '../../assets/reports-icon-active.png';
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Box, Image, HStack, Button, Text } from '@chakra-ui/react';
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom';
const Navbar = () => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveRoute(path);
  };

  const getLinkStyle = (path) => ({
    color: activeRoute === path ? '#2593D6' : '#656A72'
  });

  const getImageSrc = (path, defaultIcon, activeIcon) => (
    activeRoute === path ? activeIcon : defaultIcon
  );

  const {setviewAddProperty, setUser, setToken,FirstName, LastName} = useContext(AuthContext);
  const UserName = `${FirstName.charAt(0)}${LastName.charAt(0)}`;
 const navigate = useNavigate()
  return (
    <HStack h={'10vh'} w={'90%'} justifyContent={'space-between'} alignItems={'center'} p={3}>
      <Box w={'28%'}>
        <Image src={logo} alt='' h={'100%'} w={'40%'} />
      </Box>
      <HStack
        backgroundColor={'#FFFFFF'}
        boxShadow={'0px 10px 20px #0000000B'}
        h={'100%'}
        borderRadius={'lg'}
        px={3}
        w={'32%'} justifyContent={'space-evenly'} alignItems={'center'}>
        <Link to={'/'} onClick={() => handleLinkClick('/')}>
          <HStack h={'100%'} w={'100%'} position="relative"
            justifyContent={'center'}
            alignItems={'center'}
            gap={1}
          >
            <Image src={getImageSrc('/', dashboard_icon, dashboard_icon_active)} alt='' h={5} w={5} />
            <Text fontSize={'sm'} style={getLinkStyle('/')}
            >Dashboard</Text>
            {activeRoute === '/' && (
              <Box
                position="absolute"
                borderRadius={'3px'}
                bottom={0} left="25%" w="40%" h="2px" bg="#2593D6" />
            )}
          </HStack>
        </Link>
        <Link to={'/calender'} onClick={() => handleLinkClick('/calender')}>
          <HStack h={'100%'} w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={1}
            position="relative">
            <Image src={getImageSrc('/calender', calender_icon, calender_icon_active)} alt='' h={5} w={5} />
            <Text fontSize={'sm'} style={getLinkStyle('/calender')}>Calender</Text>
            {activeRoute === '/calender' && (
              <Box position="absolute" bottom={0} left="25%" w="40%"
                borderRadius={'3px'}
                h="2px" bg="#2593D6" />
            )}
          </HStack>
        </Link>
        <Link to={'/reports'} onClick={() => handleLinkClick('/reports')}>
          <HStack h={'100%'} w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={1}
            position="relative">
            <Image src={getImageSrc('/reports', reports_icon, reports_icon_active)} alt=''
              h={5} w={5} />
            <Text fontSize={'sm'} style={getLinkStyle('/reports')}>Reports</Text>
            {activeRoute === '/reports' && (
              <Box position="absolute" borderRadius={'3px'} bottom={0} left="25%" w="40%" h="2px" bg="#2593D6" />
            )}
          </HStack>
        </Link>
      </HStack>
      <HStack
        h={'100%'}
        w={'28%'}
        justifyContent={'space-evenly'} alignItems={'center'}>
        <Button h={'80%'}
          backgroundColor={'#2C80FF'}
          borderRadius={'lg'}
          color={'#FFFFFF'}
          fontSize={'small'} w={'45%'}
          _hover={
            {
              backgroundColor:'rgba(40, 120, 245, 1)'
            }
          }
          onClick={()=> setviewAddProperty(true)}
          >
          Add new property
        </Button>
        <Image src={search_icon} alt='' height={'20px'} w={'20px'} />
        <Image src={message_icon} alt='' height={'20px'} w={'20px'} />
        <MdOutlineNotificationsActive size={20} color='#656A72' />
        <HStack h={'100%'} w={'15%'} justifyContent={'center'}
          gap={2}>
          <Box h={'70%'} w={'70%'} display={'flex'}
            alignItems={'center'}
            color={'#2C80FF'}
            fontWeight={600}
            justifyContent={'center'} borderRadius={'50%'} boxShadow={'0px 3px 6px rgba(0, 0, 0, 0.16)'}
            onClick={()=>{
              setUser(false)
              setToken(null);
              localStorage.removeItem("userId")
              navigate('/')
            }}
            >
              {UserName}
          </Box>
          <Image src={menu_icon} alt='' />
        </HStack>
      </HStack>
    </HStack>
  );
}

export default Navbar;
