import { Flex, VStack, Box, Image, HStack, Text, Button, FormLabel, Input, Textarea } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const PropertyForm = () => {
    const [toclient, settoclient] = useState(false)
    const {Token} = useContext(AuthContext)
    const [selectedImages, setSelectedImages] = useState([]);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        // images: selectedImages,
        toclient:toclient,
        Propertytype: '',
        address: '',
        aptunit:'',
        city:'',
        state:'',
        zipcode:'',
        additionalinfo:''
      });
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(files);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        selectedImages.forEach((images, index) => {
            data.append(`images[${index}]`, images);
        });
        data.append('toclient', formData.toclient);
        data.append('Propertytype', formData.Propertytype);
        data.append('additionalinfo', formData.additionalinfo);
        data.append('zipcode', formData.zipcode);
        data.append('state', formData.state);
        data.append('city', formData.city);
        data.append('aptunit', formData.aptunit);
        try {
            const response = await axios.post('https://dev-ndd-newapi.flynautstaging.com/api/client/insertproperty', formData, {
                headers: {
                    'Authorization': `Bearer ${Token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                toast.success('Property inserted successfully');
                console.log(response.data)
                navigate("/dashboard")
            } else {
                console.error('Error inserting property:', response.statusText);
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };
    return (
        <Flex h={'100%'} w={'85%'}
            justifyContent={'space-between'} alignItems={'flex-start'} gap={0}
            border={'1px solid #FFFFFF'}
            boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
            borderRadius="24px"
            bg={'rgba(242, 242, 250, 0.7)'}
        >
            <VStack h={'100%'} w={'55%'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                pl={8}
                py={5}
            >
                <Text fontSize={'md'}
                    fontWeight={600}
                    my={1}
                    h={'5%'}
                >
                    Add new property
                </Text>
                <HStack
                    h={'9%'}
                    w={'100%'}
                    justifyContent={'flex-start'} alignItems={'center'}
                    gap={3}
                >
                    <Text fontSize={'small'}
                        color={'rgba(0, 0, 0, 0.5)'}
                    >Are you adding this property to this client?</Text>
                    <Button
                        borderRadius='50%'
                        h={'99%'}
                        w={'7%'}
                        color={toclient ? '#FFFFFF' : '#656A72'}
                        bg={toclient ? '#2C80FF' : '#FFFFFF'}
                        onClick={() => settoclient(true)}
                    >
                        Yes
                    </Button>
                    <Button
                        borderRadius='50%'
                        h={'99%'}
                        w={'7%'}
                        color={!toclient ? '#FFFFFF' : '#656A72'}
                        bg={!toclient ? '#2C80FF' : '#FFFFFF'}
                        onClick={() => settoclient(false)}
                    >
                        No
                    </Button>
                    <Input type='hidden' name='toclient' value={toclient}/>

                </HStack>
                <VStack h={'85%'}
                    w={'100%'}
                    justifyContent={'flex-start'}
                    align={'flex-start'}
                    gap={0}
                    my={1}
                >
                    {
                        toclient ? <Flex h={'14%'} w={'95%'} justifyContent={'space-between'}
                            mb={1}
                        >
                            <VStack h={'100%'} w={'47%'} justifyContent={'center'}
                                alignItems={'flex-start'}
                                gap={1}
                            >
                                <FormLabel fontSize={'x-small'}
                                    color={'#656A72'}>
                                    First Name
                                </FormLabel>
                                <Input type='text' placeholder='Enter First Name'
                                    bg={'#F2F2F2'}
                                    fontSize={'x-small'}
                                    color={'rgba(101, 106, 114, 0.5)'}
                                    border={'1px solid #CCCCCC'}
                                />
                            </VStack>
                            <VStack h={'100%'} w={'47%'} justifyContent={'center'}
                                alignItems={'flex-start'}
                                gap={1}>
                                <FormLabel fontSize={'x-small'}
                                    color={'#656A72'}
                                >
                                    Last Name
                                </FormLabel>
                                <Input type='text' placeholder='Enter Last Name'
                                    bg={'#F2F2F2'}
                                    fontSize={'x-small'}
                                    color={'rgba(101, 106, 114, 0.5)'}
                                    border={'1px solid #CCCCCC'}
                                />
                            </VStack>
                        </Flex> : ''
                    }
                    <FormLabel fontSize={'x-small'}
                        color={'#656A72'}
                        mb={1}
                    >
                        Property Type
                    </FormLabel>
                    <Input type='text' placeholder='enter property type' h={'9%'} w={'40%'}
                        bg={'#F2F2F2'}
                        fontSize={'x-small'}
                        name='Propertytype'
                        onChange={handleChange}
                        value={formData.Propertytype}
                        border={'1px solid #CCCCCC'}
                        color={'rgba(101, 106, 114, 0.5)'}
                    />
                    <Flex h={'18%'} w={'95%'} justifyContent={'space-between'}
                        my={1}
                    >
                        <VStack h={'100%'} w={'47%'} justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <FormLabel fontSize={'x-small'}
                                color={'#656A72'}>
                                Subject Property Address *
                            </FormLabel>
                            <Input type='text' placeholder='Enter your subject property'
                                bg={'#F2F2F2'}
                                name='address'
                                fontSize={'x-small'}
                                value={formData.address}
                                onChange={handleChange}
                                color={'rgba(101, 106, 114, 0.5)'}
                                border={'1px solid #CCCCCC'}
                            />
                        </VStack>
                        <VStack h={'100%'} w={'47%'} justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel fontSize={'x-small'}
                                color={'#656A72'}
                            >
                                Apt. / Unit #
                            </FormLabel>
                            <Input type='text' placeholder='Enter #'
                                bg={'#F2F2F2'}
                                name='aptunit'
                                value={formData.aptunit}
                                onChange={handleChange}
                                fontSize={'x-small'}
                                color={'rgba(101, 106, 114, 0.5)'}
                                border={'1px solid #CCCCCC'}
                            />
                        </VStack>
                    </Flex>
                    <Flex h={'18%'} w={'95%'} justifyContent={'space-between'}
                        my={1}
                    >
                        <VStack h={'100%'} w={'32%'} justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <FormLabel fontSize={'x-small'}
                                color={'#656A72'}>
                                City *
                            </FormLabel>
                            <Input type='text' placeholder='Enter your City'
                                bg={'#F2F2F2'}
                                onChange={handleChange}
                                name='city'
                                value={formData.city}
                                fontSize={'x-small'}
                                color={'rgba(101, 106, 114, 0.5)'}
                                border={'1px solid #CCCCCC'}
                            />
                        </VStack>
                        <VStack h={'100%'} w={'32%'} justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel fontSize={'x-small'}
                                color={'#656A72'}
                            >
                                State *
                            </FormLabel>
                            <Input type='text' placeholder='Enter your state'
                                bg={'#F2F2F2'}
                                name='state'
                                fontSize={'x-small'}
                                onChange={handleChange}
                                value={formData.state}
                                color={'rgba(101, 106, 114, 0.5)'}
                                border={'1px solid #CCCCCC'}
                            />
                        </VStack>
                        <VStack h={'100%'} w={'32%'} justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel fontSize={'x-small'}
                                color={'#656A72'}
                            >
                                Zip Code *
                            </FormLabel>
                            <Input type='text' placeholder='Enter Zip Code'
                                bg={'#F2F2F2'}
                                name='zipcode'
                                onChange={handleChange}
                                value={formData.zipcode}
                                fontSize={'x-small'}
                                color={'rgba(101, 106, 114, 0.5)'}
                                border={'1px solid #CCCCCC'}
                            />
                        </VStack>
                    </Flex>
                    <Flex h={'30%'} w={'95%'} justifyContent={'flex-start'}
                        my={1}
                    >
                        <VStack h={'100%'} w={'100%'} justifyContent={'flex-start'}
                            gap={1}
                            alignItems={'flex-start'}>
                            <FormLabel fontSize={'x-small'}
                                color={'#656A72'}
                                h={'25%'}
                            >
                                Additional Information
                            </FormLabel>
                            <Textarea type='text' placeholder='Additional Information'
                                bg={'#F2F2F2'}
                                name='additionalinfo'
                                value={formData.additionalinfo}
                                onChange={handleChange}
                                fontSize={'x-small'}
                                color={'rgba(101, 106, 114, 0.5)'}
                                border={'1px solid #CCCCCC'}
                                w={'100%'}
                                h={'75%'}
                            />
                        </VStack>
                    </Flex>
                    <VStack h={'10%'} w={'100%'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    gap={2}
                    >
                        <Flex flexDirection={'column'}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                        h={'100%'}
                        w={'100%'}
                    >
                        <Text fontSize={'x-small'}
                            color={'#656A72'}
                        >Upload Images & Documents
                        </Text>
                        <Input type='file' name='images'
                        onChange={handleImageChange}
                        h={'50%'} w={'40%'} />
                    </Flex>
                    <Flex mt={2}>
                        {selectedImages.map((image, index) => (
                            <Box key={index} mr={2}>
                                <Image src={URL.createObjectURL(image)} alt={`Selected image ${index + 1}`} boxSize="50px" objectFit="cover" />
                            </Box>
                        ))}
                    </Flex>
                    </VStack>
                    
                </VStack>
            </VStack>
            <VStack
                h={'100%'} w={'40%'}
                justifyContent={'center'}
                alignItems={'center'}
                pr={8}
                py={6}
            >
                <Box h={'60%'} w={'100%'}
                    bg={'#CCCCCC'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    textAlign={'center'}
                    py={'40%'}
                >Map Integration
                </Box>
                {/* <Image src='' alt='' /> */}
                <Flex w={'100%'} justifyContent={'flex-end'} alignItems={'center'}
                    gap={4}
                >
                    <Button borderRadius={'11px'}
                        border={'1px solid #2C80FF'}
                        bg={'none'}
                        color={'#2C80FF'}
                        w='35%'
                    >
                        <NavLink to={'/dashboard'}>
                            <Text fontSize={'12px'}>
                                Back to Dashboard
                            </Text>

                        </NavLink>

                    </Button>
                    <Button borderRadius={'11px'}
                        bg={'#2C80FF'}
                        color={'#FFFFFF'}
                        w='35%'
                        onClick={handleSubmit}
                    >
                        {/* <NavLink to={'/dashboard'}> */}
                            <Text fontSize={'11px'}>
                                Add to My Properties
                            </Text>

                        {/* </NavLink> */}

                    </Button>
                </Flex>
                <HStack>

                </HStack>
            </VStack>

        </Flex>
    )
}

export default PropertyForm