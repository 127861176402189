import { Checkbox, HStack, Text, VStack, Flex, Image, Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import property_icon from '../../assets/add-single-property-icon.png';
import user_icon from '../../assets/user_icon.png';
import clock_icon from '../../assets/clock_icon.png';
import property_type_icon from '../../assets/property_type_icon.png';
import link_icon from '../../assets/link_icon.png'
import dots_icon from '../../assets/dots_icon.png';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
const Card = (props) => {
     
  const {FirstName, LastName} = useContext(AuthContext)
     const [isHover, setisHover] = useState(false)
     const time = props.item.updatedAt;
     const formatDate = (time) => {
      const date = new Date(time);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = String(date.getUTCFullYear()).slice(-2);
      
      return `${day}/${month}/${year}`;
    };
   const formattedDate = formatDate(time)
  return (
    <VStack gap={0} h={'330px'} w={'300px'}
    onMouseEnter={()=>setisHover(true)}
    onMouseLeave={()=> setisHover(false)}
    >
       <VStack h={'300px'} w={'300px'} bg={'#FFFFFF'}
      borderRadius={'xl'}
      p={3}
      justifyContent={'space-between'}
      alignItems={'center'}
      _hover={
        {
          boxShadow:'rgba(0, 0, 0, 0.35) 0px 3px 10px'
      }
      }
    >
      <HStack
        h={'25%'}
        w={'95%'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <VStack h={'100%'}
          w={'90%'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          gap={1}
        >
          <Text fontSize={'smaller'}
            fontWeight={600}
          >{props.item.address}</Text>
          <Text fontSize={'small'}>{props.item.state}, {props.item.city}, {props.item.zipcode}</Text>
        </VStack>
        <Checkbox width={'10%'} mt={1} />
      </HStack>
      <VStack
        h={'70%'}
        w={'95%'}
        justifyContent={'space-between'} alignItems={'flex-start'}>
        
        <Flex gap={2}>
          <Image src={property_icon} alt='' w={'10%'}/>
        <Text fontSize={'small'}>
          Propperty ID: {props.item.aptunit}
        </Text>  
        </Flex>
        <HStack h={'20%'} w={'100%'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <Flex gap={2} justifyContent={'flex-start'} h={'100%'} w={'50%'} alignItems={'center'}>
            <Image src={clock_icon} alt='' w={'20%'} h={'60%'} />
            <Text fontSize={'small'}>
              <Text>
                {formattedDate}
              </Text>
              </Text>
          </Flex>
          <Flex gap={2} justifyContent={'flex-start'} h={'100%'} w={'50%'} alignItems={'center'}>
            <Image src={property_type_icon} alt='' w={'20%'} h={'60%'}/>
            <Text fontSize={'small'}>{props.item.Propertytype}</Text>
          </Flex>
        </HStack>
        <Flex gap={2}>
        <Image src={user_icon} alt='' w={4}/>
        <Text fontSize={'small'}>{props.item.toclient ? `${FirstName} + ${LastName}`: 'NA'}</Text>
        </Flex>
        <HStack 
        w={'100%'}
        h={'30%'}
        justifyContent={'space-between'} alignItems={'center'}>
        <Flex gap={3}>
         <Image src={link_icon} alt='' h={5}/>
         <Text fontSize={'small'}
         color={'#2C80FF'}
         fontWeight={600}
         >view attachments</Text>
        </Flex>
        <Image src={dots_icon} alt=''/>
        </HStack>
      </VStack>
    </VStack>
    {
      isHover ?     <Flex
      h={'30px'} w={'230px'}
    justifyContent={'center'} alignItems={'flex-start'}>
    <Button w={'70%'} bg={'#2C80FF'}
    h={'100%'}
    borderRadius={'0px 0px 8px 8px'}
    color={'#FFFFFF'}
    fontSize={'small'}
    _hover={
      {
        bg:'rgba(40, 125, 253, 1)'
      }
    }
    
    >
      Create an order
    </Button>
    </Flex>: ''
    }

    </VStack>
  )
}

export default Card