import React, { useEffect, useContext, useState } from 'react';
import background from '../../assets/dashboard-background.png';
import { VStack, Image, Text, Button, HStack, Box, Flex, Checkbox } from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import AddProperty from './AddProperty';
import axios from 'axios';
import { Routes, Route, NavLink } from 'react-router-dom';
import MyProperties from './MyProperties';
import MyOrders from './MyOrders';
import CounterOffer from './CounterOffer';
import ArchieveList from './ArchieveList';
import { useLocation } from 'react-router-dom';
import myproperties_icon from '../../assets/my-properties-icon.png'
import myproperties_active_icon from '../../assets/my-properties-active-icon.png'
import myorders_icon from '../../assets/my-orders-icon.png';
import myorders_active_icon from '../../assets/my-orders-active-icon.png'
const Dashboard = () => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);
  const { viewAddProperty, setviewAddProperty, setPropertiesArray, PropertiesArray, Token } = useContext(AuthContext);
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get("https://dev-ndd-newapi.flynautstaging.com/api/client/getallproperties", {
          headers: {
            Authorization: `Bearer ${Token}`
          }
        });
        setPropertiesArray(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };


    if (Token) {
      fetchProperties();
    }
  }, [Token, setPropertiesArray, PropertiesArray]);


  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  const handleLinkClick = (path) => {
    return () => setActiveRoute(path);
  };

  const getLinkStyle = (path) => ({
    color: activeRoute === path ? '#2593D6' : '#1E2021'
  });

  const getImageSrc = (path, defaultIcon, activeIcon) => (
    activeRoute === path ? activeIcon : defaultIcon
  );

  return (
    <>
      {PropertiesArray.length !== 0 ? (<>
        <VStack h={'98%'} w={'85%'} px={2} justifyContent={'space-between'} alignItems={'flex-start'} gap={0}>
          <HStack h={'10%'} w={'85%'} justifyContent={'center'} gap={3} alignItems={'flex-end'}>
            <NavLink to={'myproperties'} style={{ height: '100%', width: '20%' }}>
              <Button
                h={'100%'}
                w={'100%'}
                bg={activeRoute === '/' ? '#FFFFFF' : '#F2F2F2'}
                fontSize={'sm'}
                borderRadius={'20px 20px 0px 0px'}
                onClick={handleLinkClick('/')}
                gap={2}
                _hover={{ bg: activeRoute === '/' ? '#FFFFFF' : '#E0E0E0' }}
              >
                <Image src={getImageSrc('/', myproperties_icon, myproperties_active_icon)} alt='' />
                <Text fontSize={'sm'} style={getLinkStyle('/')} >My Properties</Text>
              </Button>
            </NavLink>
            <NavLink to={'myorders'} style={{ height: '100%', width: '20%' }}>
              <Button
                h={'100%'}
                w={'100%'}
                bg={activeRoute === 'myorders' ? '#FFFFFF' : '#F2F2F2'}
                gap={2}
                _hover={{ bg: activeRoute === 'myorders' ? '#FFFFFF' : '#E0E0E0' }}
                fontSize={'sm'}
                borderRadius={'20px 20px 0px 0px'}
              >
                <Image src={getImageSrc('myorders', myorders_icon, myorders_active_icon)} alt='' />
                <Text fontSize={'sm'} style={getLinkStyle('myorders')} >My Orders</Text>

              </Button>
            </NavLink>
            <NavLink to={'archieveList'} style={{ height: '100%', width: '20%' }}>
              <Button
                h={'100%'}
                w={'100%'}
                bg={activeRoute === 'myorders' ? '#FFFFFF' : '#F2F2F2'}
                gap={2}
                _hover={{ bg: activeRoute === 'myorders' ? '#FFFFFF' : '#E0E0E0' }}
                fontSize={'sm'}
                borderRadius={'20px 20px 0px 0px'}
              >
                                <Image src={getImageSrc('myorders', myorders_icon, myorders_active_icon)} alt='' />
                <Text fontSize={'sm'} style={getLinkStyle('myorders')} >
                Archieve List   </Text>
               
              </Button>
            </NavLink>
            <NavLink to={'counteroffer'} style={{ height: '100%', width: '20%' }}>
              <Button
                h={'100%'}
                w={'100%'}
                color={'#1E2021'}
                bg={'#F2F2F2'}
                fontSize={'sm'}
                borderRadius={'20px 20px 0px 0px'}
              >
                Counter Offer
              </Button>
            </NavLink>
          </HStack>
          <Box
            h={'90%'}
            w={'100%'}
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            border={'1px solid #FFFFFF'}
            boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
            borderRadius="24px"
            bg={'rgba(242, 242, 250, 0.7)'}
          >
            <Routes>
              <Route path="myproperties" element={<MyProperties />} />
              <Route path="myorders" element={<MyOrders />} />
              <Route path="archieveList" element={<ArchieveList />} />
              <Route path="counteroffer" element={<CounterOffer />} />
              <Route path="/" element={<MyProperties />} />
            </Routes>
          </Box>
        </VStack>
        <Flex h={'7vh'}
        w={'28%'}
        position={'absolute'}
        bottom={'60px'}
        right={'150px'}
        borderRadius={'xl'}
        bg={'#2C80FF'}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={3}
        boxShadow={'0px 20px 20px #0000000B'}
        >
        <Checkbox h={4} w={4}/>
        <Text fontSize={'xx-small'} color={'#FFFFFF'}>All Property Selected</Text>
        <Button
        bg={'#FFFFFF'}
        _hover={
          {bg:'#FFFFFF'}
        }
        h={'70%'}
        w={'30%'}
        fontSize={'xx-small'}
        color={'#2C80FF'}
        >
          Order Appraisal
        </Button>
        <Text fontSize={'xx-small'} color={'#FFFFFF'}>Download</Text>
        <Text fontSize={'xx-small'} color={'#FFFFFF'}>Archieve</Text>
        </Flex>
        </>
      ) : (
        <VStack h={'75%'} w={'38%'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <Image src={background} alt="" m={3} h={'45%'} />
          <Text
            h={'20%'}
            w={'100%'}
            color={'#191A1C'}
            fontSize={'27px'}
            fontWeight={600}
            textAlign={'center'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            Hi Kumar, Welcome to <br />
            <Text as={'span'} fontWeight={400} color="#2593D6">
              Next Day Desktop Valuations
            </Text>
          </Text>
          <Text color={'#191A1C'} textAlign={'center'} fontSize={'13px'} w={'88%'}>
            There are no New Appraisals ordered yet.
            <br />
            If you want to create new appraisals you can find the options below.
          </Text>
          <Button
            marginTop={5}
            h={'8%'}
            w={'65%'}
            bgColor={'#2C80FF'}
            color={'#FFFFFF'}
            fontSize={'sm'}
            borderRadius={'md'}
            _hover={{
              backgroundColor: 'rgba(40, 120, 245, 1)',
            }}
            onClick={() => setviewAddProperty(true)}
          >
            Add New Property
          </Button>
        </VStack>
      )}

      {viewAddProperty ? <AddProperty /> : ''}
    </>
  );
};

export default Dashboard;
