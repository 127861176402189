import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import { AuthContext } from './context/AuthContext';
import { useContext } from 'react';
import Dashboard from "./components/dashboard/Dashboard";
import Calender from "./components/calender/Calender";
import Reports from "./components/reports/Reports";
import Navbar from './components/common/Navbar';
import { Box, Container, VStack, HStack, Text } from '@chakra-ui/react';
import background from './assets/background-home.png';
import Verification from './components/auth/Verification';
import PropertyForm from './components/dashboard/PropertyForm';
import Forget from './components/auth/Forget';

function App() {
  const { User } = useContext(AuthContext);
  return (
    <Router>
      {
        User ?
          <Container h={'100vh'} maxW={'100vw'} p={0} backgroundColor={'#F8FAFB'}>
            <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}
              backgroundImage={background}
              backgroundSize={'cover'}
              backgroundPosition={'center'}
            >
              <Navbar />
              <Box h={'83%'} w={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Routes>
                  <Route path='/' element={<Navigate to={'/dashboard'} />} />
                  <Route path='/dashboard/*' element={<Dashboard />} />
                  <Route path='/property-form' element={<PropertyForm />} />
                  <Route path='/calender' element={<Calender />} />
                  <Route path='/reports' element={<Reports />} />
                </Routes>
              </Box>
              <HStack h={'5%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'} px={'100px'} py={2}>
                <Text w={'75%'} fontSize={'11px'} color={'#656A72'}>Nextday Desktop Valuations. all rights reserved</Text>
                <Text w={'10%'} fontSize={'11px'} color={'#656A72'}>Terms of Service</Text>
                <Text w={'10%'} fontSize={'11px'} color={'#656A72'}>Privacy Policy</Text>
              </HStack>
            </VStack>
          </Container>
          :
          <Routes>
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Navigate to={'/login'} />} />
            <Route path='/forget' element={<Forget/>}/>
            <Route path='/verification' element={<Verification />} />
          </Routes>
      }
    </Router>
  );
}

export default App;
