import React, { useState, createContext, useEffect } from 'react'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {


  const [userId, setuserId] = useState(localStorage.getItem("userId") || null)
  const [Token, setToken] = useState("")
  const [User, setUser] = useState(!!userId)
  const [viewAddProperty, setviewAddProperty] = useState(false)
  const [PropertiesArray, setPropertiesArray] = useState([])
  const [Verifyemail, setVerifyemail] = useState("");
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("");
  const [userMail, setuserMail] = useState("")
  useEffect(() => {
    if (userId && Token) {
      console.log(`User ID: ${userId} Token: ${Token}` );
      console.log(PropertiesArray)
    }
  }, [userId, Token, PropertiesArray]);


  return (
    <AuthContext.Provider value={{ User, setUser, viewAddProperty, setviewAddProperty, 
    userId, setuserId, PropertiesArray, setPropertiesArray, Verifyemail, setVerifyemail, 
    Token, setToken, FirstName, setFirstName, LastName, setLastName, userMail, setuserMail}}>
      {children}
    </AuthContext.Provider>
  )
}