import { Card, Container, HStack, Text, VStack, Button, Image, Box } from '@chakra-ui/react'
import React from 'react'
import close_icon from '../../assets/close-btn.png';
import single_property_icon from '../../assets/add-single-property-icon.png';
import xls_icon from '../../assets/xls-icon.png'
import download_icon from '../../assets/download_icon.png'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext'
import { NavLink} from 'react-router-dom';
const AddProperty = () => {
    const { setviewAddProperty } = useContext(AuthContext)
    return (
        <Container h={'100vh'} maxW={'100vw'} display={'flex'} justifyContent={'center'}
            alignItems={'center'}
            position={'absolute'}
            top={0}
            left={0}
            bgColor={'rgba(0, 0, 0, 0.3)'}
        >
            <Card h={'48%'} w={'40%'} bgColor={'#FFFFFF'}
                justifyContent={'center'}
                alignItems={'center'}
                boxShadow={'0px 20px 20px #0000000B'}
                borderRadius={'15px'}
            >
                <VStack h={'100%'} w={'100%'} p={4}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <HStack h={'8%'} w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Text
                            fontWeight={600}
                            fontSize={'sm'}
                            h={'100%'}
                            w={'95%'}
                            textAlign={'center'}
                            pl={8}
                        >
                            Add New Property
                        </Text>
                        <Image src={close_icon} alt=''
                            h={10}
                            w={10}
                            _hover={{
                                cursor: 'pointer'
                            }}
                            onClick={() => setviewAddProperty(false)}
                        />
                    </HStack>
                    <HStack height={'64%'}
                        w={'100%'}
                        justifyContent={'center'} gap={4}>

                        <Box as={NavLink} to={'/property-form'} 
                        display={'flex'}
                        flexDirection={'column'}
                        height={'95%'}
                            p={2}
                            borderRadius={'18px'}
                            w={'36%'}
                            bgColor={'#F9F9F9'}
                            onClick={() => setviewAddProperty(false)
                            }
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                                <Image src={single_property_icon} alt=''
                                    h={'53%'}
                                    w={'50%'}
                                    mt={1}
                                />
                                <Text
                                    color={'#33363A'}
                                    fontWeight={600}
                                    fontSize={'sm'}
                                    textAlign={'center'}
                                    width={'100%'}
                                >Add Single Property</Text>
                               
                                <Text
                                    fontSize={'10px'}
                                    color={'#71706F'}
                                    textAlign={'center'}
                                    width={'100%'}
                                >Lorem Ipsum is simply dummy text of the printing</Text>
                            

                        </Box>

                        <VStack height={'95%'}
                            p={2}
                            borderRadius={'18px'}
                            w={'36%'}
                            bgColor={'#F9F9F9'}
                        >
                            <Image src={xls_icon} alt=''
                                mt={1}
                                h={'55%'}
                            />
                            <Text
                                color={'#33363A'}
                                fontWeight={600}
                                fontSize={'sm'}
                                textAlign={'center'}
                                width={'100%'}
                            >Add Multipul Property</Text>
                            <Text
                                fontSize={'10px'}
                                color={'#71706F'}
                                textAlign={'center'}
                                width={'100%'}
                            >Lorem Ipsum is simply dummy text of the printing</Text>
                        </VStack>
                    </HStack>
                    <HStack h={'16%'}
                        w={'92%'}
                        mt={1}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <VStack h={'100%'} w={'75%'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={1}
                        >
                            <Text
                                color={'#000000'}
                                fontSize={'11px'}
                                fontWeight={600}
                                w={'100%'}
                            >Download Sample file</Text>
                            <Text
                                color={'#71706F'}
                                fontSize={'9px'}
                                w={'100%'}
                            >
                                Lorem Ipsum is simply dummy text of the simply dummy text of the printing
                            </Text>
                        </VStack>
                        <Button w={'22%'}
                            h={'75%'}
                            bgColor={'#D2FBDF'}
                            borderRadius={'11px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={1}
                            _hover={
                                { bgColor: 'rgba(200, 251, 210, 1)' }
                            }
                        >
                            <Image src={download_icon} alt=''

                            />
                            <Text
                                color={'#16C672'}
                                fontSize={'9px'}
                                w={'95%'}
                            >Download</Text>
                        </Button>
                    </HStack>
                </VStack>
            </Card>
        </Container>

    )
}

export default AddProperty