import React from 'react'
import Card from '../common/Card'
import add_icon from '../../assets/add_property_second_icon.png'
import { Container, Button, Flex, HStack, Text, VStack, Image, Input } from '@chakra-ui/react'
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';

const MyProperties = () => {
  const { setviewAddProperty, PropertiesArray } = useContext(AuthContext);
  return (
    <Container h={'90%'} w={'93%'} maxW={'93%'} overflowY={'auto'}>
      <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Text
          color={'rgba(0, 0, 0, 0.5)'}
          fontSize={'small'}
          h={'5%'}
          w={'100%'}
          textAlign={'left'}
        >
          Access all NextDay products in one place
        </Text>
        <HStack h='10%'
          w='100%' justifyContent={'space-between'} alignItems={'center'}>
          <Flex gap={2} h={'100%'} w={'70%'} justifyContent={'flex-start'} alignItems={'center'}>
            <Text fontSize={'small'} h={'60%'}
              w={'20%'} color={'#000000'}
              textAlign={'center'}
            >Property type</Text>
            <Input type='text' h={'60%'} w={'20%'}
              bg={'#EAEAEA'}
              borderRadius={'xl'}
              _focusVisible={'none'}
              fontSize={'x-small'}
            />
            <Text fontSize={'small'} h={'60%'}
              w={'12%'} color={'#000000'}
              textAlign={'center'}
            >State</Text>
            <Input type='text' h={'60%'} w={'15%'}
              bg={'#EAEAEA'}
              borderRadius={'xl'}
              _focusVisible={'none'}
              fontSize={'x-small'}
            />
            <Text fontSize={'smaller'} h={'60%'}
              w={'20%'} color={'#2C80FF'}
              textAlign={'center'}
            >Clear filters</Text>
          </Flex>
          <Flex gap={4} w={'70%'}
            justifyContent={'flex-end'} alignItems={'center'}
          >
            <Button
              bg={'#2C80FF'}
              color={'#FFFFFF'}
              fontSize={'small'}
              _hover={
                {
                  bg: '#2C80FF'
                }
              }
              borderRadius={'xl'}
              w={'20%'}
            >Sort</Button>
            <Button
              bg={'#2C80FF'}
              color={'#FFFFFF'}
              fontSize={'small'}
              _hover={
                {
                  bg: '#2C80FF'
                }
              }
              borderRadius={'xl'}
              w={'20%'}
            >Filter</Button>
          </Flex>
        </HStack>
        <Flex
          h={'85%'}
          w={'100%'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={4}
          p={2}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          overflowY={'auto'}
        >
          <VStack
            h={'300px'}
            w={'290px'}
            bg={'#FFFFFF'}
            borderRadius={'xl'}
            justifyContent={'space-between'}
            alignContent={'center'}
            p={5}
            onClick={() => setviewAddProperty(true)}
            _hover={{ cursor: 'pointer' }}
          >
            <Image src={add_icon} alt='' h={'50%'} />
            <Text
              fontSize={'small'}
              color={'#71706F'}
              h={'20%'}
              w={'80%'}
              textAlign={'center'}
            >
              Lorem Ipsum is simply dummy text of the printing
            </Text>
            <Text
              fontSize={'sm'}
              color={'#2C80FF'}
              h={'20%'}
              w={'80%'}
              textAlign={'center'}
              fontWeight={600}
            >
              Add New Property
            </Text>
          </VStack>
          {
            PropertiesArray.map((item, index) => {
              return <Card key={index} item={item} index={index} />
            })
          }
        </Flex>
      </VStack>
    </Container>
  )
}

export default MyProperties